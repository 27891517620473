import { SelectOption } from 'src/types';

type Props = {
  options: SelectOption[] | null | undefined;
};

function SelectOptions({ options }: Props): JSX.Element {
  return (
    <>
      {options
        ? options.map(({ label, value }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))
        : null}
    </>
  );
}

export default SelectOptions;
