import { useRef, useEffect } from 'react';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

type Props = {
  url: string;
  className?: string;
};

function EmbedDashboard({ url, className }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current as HTMLElement;
    container.innerHTML = ''; // Clean up before embedding

    embedDashboard({
      url,
      container,
      parameters: {},
      scrolling: 'no',
      width: '100%',
      height: 'AutoFit',
      locale: 'en-US',
      footerPaddingEnabled: false,
      sheetTabsDisabled: false,
      printEnabled: false,
      undoRedoDisabled: true,
      resetDisabled: true,
    });
  }, [url]);

  return <div className={className} ref={containerRef}></div>;
}

export default EmbedDashboard;
