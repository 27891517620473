import { dashboardApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import {
  QuickSightAnalysisResponseDto,
  QuickSightDashboardResponseDto,
  CreateQuickSightDashboardModel,
} from 'src/types';

export const getQuickSightAnalysesListAll = (config: AxiosRequestConfig = {}) =>
  dashboardApi.get<QuickSightAnalysisResponseDto[]>('/api/v1/QuickSightAnalyses/all', config);
export const getQuickSightAnalysisListByDashboard = (
  dashboardId: string,
  schemeId: string,
  config: AxiosRequestConfig = {},
) =>
  dashboardApi.get<QuickSightAnalysisResponseDto[]>(
    `/api/v1/QuickSightAnalyses/assignedToDashboardAndScheme/${dashboardId}/${schemeId}`,
    config,
  );
export const getQuickSightDashboardByAnalysis = (
  data: CreateQuickSightDashboardModel,
  config: AxiosRequestConfig = {},
) => dashboardApi.get<QuickSightDashboardResponseDto>('/api/v1/QuickSightDashboards', { params: data, ...config });
export const getQuickSightDashboardUrl = (id: string, config: AxiosRequestConfig = {}) =>
  dashboardApi.get<string>('/api/v1/QuickSightDashboards/embed/url', { params: { id }, ...config });
export const createQuickSightDashboard = (data: CreateQuickSightDashboardModel) =>
  dashboardApi.post<string>('/api/v1/QuickSightDashboards', data);
export const setQuickSightAnalysisMain = (analysisId: string) =>
  dashboardApi.patch<string>(`/api/v1/QuickSightAnalyses/${analysisId}/setMain`);

/**
 * @deprecated The method should not be used
 */
export const getQuickSightDashboardListAll = (config: AxiosRequestConfig = {}) =>
  dashboardApi.get<QuickSightDashboardResponseDto[]>('/api/v1/QuickSightDashboards/all', config);
/**
 * @deprecated The method should not be used
 */
export const getQuickSightDashboardListByDashboard = (dashboardId: string, config: AxiosRequestConfig = {}) =>
  dashboardApi.get<QuickSightDashboardResponseDto[]>(
    `/api/v1/QuickSightDashboards/assignedToDashboard/${dashboardId}`,
    config,
  );
