import { Suspense, lazy, useState, useLayoutEffect } from 'react';
import { Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RoutePath, GuardedRoute, history } from 'src/router';

import store, { useSelector } from 'src/store';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';

import AuthProvider from 'src/hocs/AuthProvider';
import SyncFiltersWithURL from 'src/hocs/SyncFiltersWithURL';
import Layout from 'src/components/layout/Layout';
import { PageLoading } from '@itm/shared-frontend/lib/components';

import DashboardPage from 'src/pages/dashboard/Dashboard';
import LoginRedirectPage from 'src/pages/loginRedirect';
import PermissionDeniedPage from 'src/pages/permissionDenied';

import { Role } from 'src/types';

const AdministrationRoutes = lazy(() => import('src/pages/admin'));

function RootRoutesFallback() {
  return (
    <div className="px-3">
      <div className="container">
        <PageLoading />
      </div>
    </div>
  );
}

function RootRoutes() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  return (
    <Layout hasHeader>
      <Suspense fallback={<RootRoutesFallback />}>
        <Routes>
          <Route
            index
            element={<Navigate to={isLoggedIn ? RoutePath.dashboard : RoutePath.loginRedirect} replace={true} />}
          />

          <Route path={RoutePath.loginRedirect} element={<LoginRedirectPage />} />
          <Route path={RoutePath.permissionDenied} element={<PermissionDeniedPage />} />
          <Route
            path={RoutePath.dashboard}
            element={
              <GuardedRoute meta={{ auth: true }}>
                <DashboardPage />
              </GuardedRoute>
            }
          />
          <Route
            path={`${RoutePath.administrationRoot}/*`}
            element={
              <GuardedRoute
                meta={{
                  auth: true,
                  role: {
                    allowList: [Role.SuperAdmin, Role.ClientAdmin],
                  },
                }}
              >
                <AdministrationRoutes />
              </GuardedRoute>
            }
          />

          <Route path="*" element={<Navigate to={RoutePath.root} />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

function RootRouter() {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), []);
  return (
    <Router location={state.location} navigationType={state.action} navigator={history}>
      <Provider store={store}>
        <AuthProvider>
          <SyncFiltersWithURL>
            <RootRoutes />
          </SyncFiltersWithURL>
        </AuthProvider>
      </Provider>
    </Router>
  );
}

export default RootRouter;
