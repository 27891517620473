import getEnvVariable from './getEnvVariable';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const ENVIRONMENT = getEnvVariable('ENVIRONMENT');
export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const DASHBOARD_API_URL = getEnvVariable('DASHBOARD_API_URL');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');

export const isProductionEnv = ENVIRONMENT === 'PROD';
