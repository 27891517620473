import axios from 'axios';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { PageLoading } from '@itm/shared-frontend/lib/components';
import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';

import Filters from 'src/components/Filters';
import EmbedDashboard from 'src/components/EmbedDashboard';

import { getQuickSightDashboardByAnalysis, getQuickSightDashboardUrl } from 'src/api/dashboard/quickSight';

import { ServerFormErrors, CreateQuickSightDashboardModel, ServerError } from 'src/types';

const defaultUrl =
  'https://eu-west-2.quicksight.aws.amazon.com/embed/d6ef641f25a240988f2454e6c2623073/dashboards/978a4ecc-768e-48f2-80eb-700220014ea4?code=AYABeLc1P80anojDb5LNkZ1QtCUAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTI6NjQ5MTA0MjA5NDE4OmtleS9lZGQ0ZjBlNC03M2U0LTQ4NGEtYjBmYy0yYjEzNDI0YThlODUAuAECAQB4mfKZEEf3mCYLMK7qssB9arvNcGIge3XFJGi4tHbJesQBmiV-SfTtdf2H6YYVI8GbYAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDOAO_SmfYEQ57PfYbQIBEIA7AqPZPqbx2fCWS5X1iXC0Tk6m8SXMPBisBcm7OdDYe08OUYv68YukmXaX9BXYtiMRVIwD0Hce4lmshY8CAAAAAAwAABAAAAAAAAAAAAAAAAAAOqCuqumz39GHHBN5w2vuBv____8AAAABAAAAAAAAAAAAAAABAAAA5bWE0_ylOQe3upWJRP7Qz2Kmyc2KtEmPADOkrpcT9SBrmQqnmnJTHOAEk2qpyZKCRVVQVHbm--NEa20dGzasMg1lKYhlmBgJGwIWNLErDz4Gajg5iDh40LtDtpL_BkkjlKgchcECKJVl2ioFC5dVoRpDrjyuwPd159pt03XMPfRMy7TR04l_KsQLgxB5MKZnjQOSJrg-OqG1Ca26U4ULfFZNtShS8yNrZOLLRaz4SFjtsVBY4uzjveTIObBuUTs2sWY0JCYD63sNcaSqrFidOehJTQr-wnfX7-u6tpZLMJ8w_Npg-BjXfIVQEEscWRT0fi1tZWWL&identityprovider=quicksight&isauthcode=true';

function Dashboard() {
  const [embedUrl, setEmbedUrl] = useState(defaultUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  const getEmbedUrl = useCallback(
    async (model: CreateQuickSightDashboardModel) => {
      setServerErrorMessages([]);

      if (abortControllerSet.size) {
        setIsLoading(false);
        cleanup();
      }

      const isValidModel = Object.values(model).every((value) => value);
      if (!isValidModel) {
        setEmbedUrl(defaultUrl);
        return;
      }
      const abortControllerQSDashboard = new AbortController();
      const abortControllerQSDashboardUrl = new AbortController();
      abortControllerSet.add(abortControllerQSDashboard);
      abortControllerSet.add(abortControllerQSDashboardUrl);

      setIsLoading(true);
      try {
        const { data: quickSightDashboard } = await getQuickSightDashboardByAnalysis(model, {
          signal: abortControllerQSDashboard.signal,
        });
        if (quickSightDashboard) {
          const { data: url } = await getQuickSightDashboardUrl(quickSightDashboard.id, {
            signal: abortControllerQSDashboardUrl.signal,
          });
          setEmbedUrl(url);
        } else {
          setEmbedUrl(defaultUrl);
        }
      } catch (e) {
        if (e instanceof axios.Cancel) return;
        const errors = new ServerErrorAdapter(e as ServerError).combine();
        setServerErrorMessages(errors);
        setEmbedUrl(defaultUrl);
      }
      setIsLoading(false);
      abortControllerSet.delete(abortControllerQSDashboard);
      abortControllerSet.delete(abortControllerQSDashboardUrl);
    },
    [abortControllerSet, cleanup],
  );
  useEffect(() => {
    return () => cleanup();
  }, [cleanup]);

  return (
    <main className="is-flex is-flex-direction-column is-flex-grow-1 pt-2 pb-5">
      <section className="is-fullwidth is-flex is-flex-direction-column is-flex-grow-1">
        <div className="is-flex is-flex-direction-column is-flex-grow-1">
          <Filters getEmbedUrl={getEmbedUrl} setServerErrorMessages={setServerErrorMessages} />
          <div className="is-flex is-multiline is-flex-grow-1">
            <div className="is-flex is-flex-direction-column is-fullwidth">
              {isLoading ? (
                <div className="mx-auto">
                  <PageLoading />
                </div>
              ) : (
                <>
                  <ServerErrorMessages messages={serverErrorMessages} className="px-2" />
                  <EmbedDashboard className="is-flex-grow-1" url={embedUrl} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Dashboard;
