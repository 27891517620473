import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch as useDispatchOriginal, useSelector } from 'react-redux';

import { resetStore } from './slices/storeSlice';
import authReducer from './slices/authSlice';
import companyReducer from './slices/companySlice';
import tenantReducer from './slices/tenantSlice';
import schemeReducer from './slices/schemeSlice';
import filterReducer from './slices/filterSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  company: companyReducer,
  schema: schemeReducer,
  filter: filterReducer,
  tenant: tenantReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type StoreDispatch = typeof store.dispatch;

export const useDispatch = () => useDispatchOriginal<StoreDispatch>();
export { useSelector, resetStore };

export default store;
