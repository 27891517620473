import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

type FilterState = {
  isActive: boolean;
};

const initialState: FilterState = {
  isActive: false,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterStatus(state: FilterState, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: FilterState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setFilterStatus } = filterSlice.actions;

export default filterSlice.reducer;
