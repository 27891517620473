import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { SelectOption } from 'src/types';

export const schemeSliceSelector = (state: RootState) => state.schema;

export const schemeListByCompanySelector = createSelector(schemeSliceSelector, (state) => state.schemeList);

export const schemeOptionsSelector = createSelector(schemeListByCompanySelector, (schemeList) =>
  schemeList
    .map<SelectOption>(({ name, id }) => ({ label: name, value: id }))
    .sort((a, b) => a.label.localeCompare(b.label)),
);

export const isLoadingSchemeSelector = createSelector(schemeSliceSelector, (state) => state.isLoading);

export const selectedSchemeIdSelector = createSelector(
  schemeSliceSelector,
  schemeOptionsSelector,
  (state, schemeOptions) => {
    if (schemeOptions.length) {
      return schemeOptions.find((option) => option.value === state.selectedSchemaId)
        ? state.selectedSchemaId
        : schemeOptions[0].value;
    }
    return state.selectedSchemaId === 'na' ? '' : state.selectedSchemaId;
  },
);

export const selectedSchemeNameSelector = createSelector(
  selectedSchemeIdSelector,
  schemeOptionsSelector,
  (id, options) => {
    if (!options.length) return '';

    const selectedSchema = options.find((option) => option.value === id);
    return selectedSchema?.label || '';
  },
);
