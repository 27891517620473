import { dashboardApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import {
  DashboardResponseDto,
  CreateDashboardModel,
  UpdateDashboardModel,
  DeleteDashboardParams,
  SchemeDashboardModel,
  QuickSightDashboardDashboardModel,
  QuickSightAnalysisToDashboardModel,
} from 'src/types';

export const getDashboardListAll = (config: AxiosRequestConfig = {}) =>
  dashboardApi.get<DashboardResponseDto[]>('/api/v1/dashboards/all', config);
export const getDashboardListBySchema = (schemeId: string, config: AxiosRequestConfig = {}) =>
  dashboardApi.get<DashboardResponseDto[]>(`/api/v1/dashboards/assignedToScheme/${schemeId}`, config);
export const getDashboardById = (dashboardId: string, config: AxiosRequestConfig = {}) =>
  dashboardApi.get<DashboardResponseDto>(`/api/v1/dashboards/${dashboardId}`, config);
export const createDashboard = (data: CreateDashboardModel) => dashboardApi.post<string>('/api/v1/dashboards', data);
export const updateDashboard = (data: UpdateDashboardModel) => dashboardApi.patch<void>('/api/v1/dashboards', data);
export const setDashboardMain = (dashboardId: string) =>
  dashboardApi.patch<void>(`/api/v1/dashboards/${dashboardId}/setMain`);
export const deleteDashboard = (dashboardId: DeleteDashboardParams) =>
  dashboardApi.delete<void>('/api/v1/dashboards', { params: dashboardId });

export const assignSchemeToDashboard = (data: SchemeDashboardModel) =>
  dashboardApi.post<void>('/api/v1/dashboards/assignScheme', data);
export const unassignSchemeFromDashboard = (data: SchemeDashboardModel) =>
  dashboardApi.post<void>('/api/v1/dashboards/unassignScheme', data);

export const assignQuickSightAnalysisToDashboard = (data: QuickSightAnalysisToDashboardModel) =>
  dashboardApi.post<void>('/api/v1/dashboards/assignQuickSightAnalysis', data);
export const unassignQuickSightAnalysisFromDashboard = (data: QuickSightAnalysisToDashboardModel) =>
  dashboardApi.post<void>('/api/v1/dashboards/unassignQuickSightAnalysis', data);

/**
 * @deprecated The method should not be used
 */
export const assignQuickSightToDashboard = (data: QuickSightDashboardDashboardModel) =>
  dashboardApi.post<void>('/api/v1/dashboards/assignQuickSightDashboard', data);
/**
 * @deprecated The method should not be used
 */
export const unassignQuickSightFromDashboard = (data: QuickSightDashboardDashboardModel) =>
  dashboardApi.post<void>('/api/v1/dashboards/unassignQuickSightDashboard', data);
