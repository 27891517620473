import { dashboardApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { CompanyResponseDto, SchemaResponseDto } from 'src/types';

export const getCompanyListAll = (config: AxiosRequestConfig = {}) =>
  dashboardApi.get<CompanyResponseDto[]>('/api/v1/companies/all', config);

export const getCompanyById = (companyId: string, config: AxiosRequestConfig = {}) =>
  dashboardApi.get<CompanyResponseDto>(`/api/v1/companies/${companyId}`, config);

export const getSchemeListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  dashboardApi.get<SchemaResponseDto[]>(`/api/v1/schemes/assignedToCompany/${companyId}`, config);
