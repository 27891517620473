export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',
  // Dashboard
  dashboard: '/dashboard',
  // Super Admin
  administrationRoot: '/administration',
  administrationDashboards: '/administration/dashboard',
  administrationProductInfo: '/administration/product-info',
  administrationDashboardAdd: '/administration/add-dashboard',
  administrationDashboardProfile: '/administration/dashboard/:dashboardId',
};

export { GuardedRoute } from './GuardedRoute';
