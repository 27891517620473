export type { SelectOption, ServerError, ServerFormErrors } from '@itm/shared-frontend/lib/types';

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Dashboard_Support',
  Analyst = 'Dashboard_Analyst',
  Viewer = 'Dashboard_Viewer',
  ClientAdmin = 'Dashboard_ClientAdmin',
  Manager = 'Dashboard_Manager',
}

export enum ImmovableDashboards {
  GMP = 'GMP',
  Audit = 'Audit',
  'Mortality Screening' = 'Mortality Screening',
}

export type CompanyResponseDto = {
  id: string;
  name: string;
};

export type SchemaResponseDto = {
  id: string;
  companyId: string;
  name: string;
};

export type DashboardResponseDto = {
  id: string;
  name: string;
  createdDate: string | null;
  updatedDate: string | null;
  isMain: boolean;
};

export type CreateDashboardModel = {
  dashboardName: string;
};

export type UpdateDashboardModel = {
  dashboardId: string;
  dashboardName: string;
};

export type DeleteDashboardParams = {
  dashboardId: string;
};

export type SchemeDashboardModel = {
  dashboardId: string;
  schemeId: string;
};

export type QuickSightDashboardDashboardModel = {
  dashboardId: string;
  quickSightDashboardId: string;
};

export type ProductInfoResponse = {
  id: string;
  version: string | null;
  description?: string;
};

export type QuickSightDashboardResponseDto = {
  arn: string | null;
  publishedVersionNumber: number;
  lastUpdatedTime: string | null;
  lastPublishedTime: string | null;
} & Omit<DashboardResponseDto, 'updatedDate'>;

export type QuickSightAnalysisResponseDto = {
  id: string;
  arn: string | null;
  name: string;
  status: string | null;
  lastUpdateTime: string | null;
  createdDate: string | null;
  isMain?: boolean;
};

export type CreateQuickSightDashboardModel = {
  schemeId: string;
  quickSightAnalysisId: string;
  dashboardId: string;
};

export type QuickSightAnalysisToDashboardModel = {
  dashboardId: string;
  quickSightAnalysisId: string;
  schemeId: string;
};
